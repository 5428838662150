const LogisticsOfficerSideMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'logistics-officer.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-home-4-line'
  },
  {
    title: 'Pending Requests',
    is_heading: false,
    is_active: false,
    link: {
      name: 'logistics-officer.pending-requests'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-file-line'
  },
  {
    title: 'Approved Requests',
    is_heading: false,
    is_active: false,
    link: {
      name: 'logistics-officer.approved-requests'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-file-2-line'
  },
  {
    title: 'Maintenances',
    name: 'sidebar.maintenances',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-settings-line',
    children: [
      {
        title: 'Vehicle Categories',
        is_active: false,
        link: {
          name: 'logistics-officer.vehicle-categories'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-car-line'
      },
      {
        title: 'Vehicles',
        is_active: false,
        link: {
          name: 'logistics-officer.vehicles'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-car-line'
      },
      {
        title: 'Suppliers',
        is_active: false,
        link: {
          name: 'logistics-officer.suppliers'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-scan-2-line'
      }
    ]
  },
  {
    title: 'Reports',
    name: 'sidebar.reports',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-chart-line',
    children: [
      {
        title: 'Vehicle Request Histories',
        is_active: false,
        link: {
          name: 'logistics-officer.vehicle-request-histories'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      }
    ]
  }
]

export default LogisticsOfficerSideMenu
